import React, { Component } from 'react';
import Loading from '../UI/Loading/Loading';
import Cookies from 'universal-cookie';
import './Survey.css';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import questionTypes from './data/avatarQuestionTypes';
import AdListItem from './templates/AdListItem.js/AdListItem';

import Pushnami from '../../components/PushnamiReact';
import Jornaya from '../../components/Jornaya';

class Survey extends Component {

    constructor(props) {
        super(props);
        this.surveyRef = React.createRef();
    }

    state = {
        dataLoaded: false,
        submitting: false,
        avtc: this.props.avtc,
        mbi: this.props.mbi,
        pushnamiOverride: this.props.pushnamiOverride,
        step: 1,
        stepList: [],
        maxStep: this.props.survey.maxStep,
        survey: this.props.survey.survey,
        events: this.props.survey.events,
        list: [],
        callbacks:[],
        iframe: '',
        scrollComplete: false,
        skip: false,
        jornaya: ''
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            dataLoaded: true
        })
    }

    scrollMobileView = () => {
        // If on mobile, scroll the survey back into view
        if (window.outerWidth < 500){
            window.scrollTo({
                top: this.surveyRef.current.getBoundingClientRect().top + window.pageYOffset - 60, //The 60 is because of the fixed navbar
                behavior: "smooth"
           });            
        }        
    }

    nextStep = async (submitSurvey) => {

        if (this.state.submitting) {
            return;
        }

        this.setState({
            ...this.state,
            submitting: true
        })

        const { step } = this.state;

        // Handle the current response
        // Get the current question
        const question = this.state.survey.find(question => question.order === step);
        const { questionId, response, type } = question;

        let requestBody = {
            "avtc": this.state.avtc,
            "mbi": this.state.mbi,
            "jornaya": this.state.jornaya,
            "type": type,
            "response": response
        };

        let fetchUrl = `${process.env.REACT_APP_API_URL}/survey/response/${this.props.propertyId}/${this.props.siteId}/${this.props.surveyId}/${questionId}`;

        if (submitSurvey) {
            // If the step is not a number, then the survey is complete
            fetchUrl = `${fetchUrl}?submit=true`
        }

        let list = [];
        let iframe = '';
        let scrollComplete = false;
        let noResponse = false;
        let callbacks = [];

        // Save the response and handle events within the API

        await fetch(fetchUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result["success"] === true) {
                        //console.log('result', result)

                        if (result.response){
                            if (result.response.length > 0){

                                result.response.forEach( response => {
                                    if (response.callback !== ""){
                                        callbacks.push(response.callback)
                                    }
                                })

                                if (submitSurvey){
                                    //console.log(1, result.response)
                                    try {
                                        // We can only action ONE event. Find the first event that requires an action.
                                        let actionedEvent = result.response.find( event => ['LIST', 'REDIRECT', 'POPUP', 'IFRAME'].includes(event.type) )

                                        if (actionedEvent){
                                            if (actionedEvent.type === 'LIST') {
                                                scrollComplete = true
                                                list = actionedEvent.response ? actionedEvent.response : [];
                                            } else if (actionedEvent.type === 'REDIRECT') {
                                                setTimeout(function () {
                                                    window.location.href = actionedEvent.response;
                                                }, 1000)
                                            } else if (actionedEvent.type === 'POPUP') {
                                                window.open(actionedEvent.response);
                                            } else if (actionedEvent.type === 'IFRAME') {
                                                scrollComplete = true
                                                iframe = actionedEvent.response ? actionedEvent.response : [];
                                            }
                                        } else {
                                            noResponse = true
                                        }
                                        
                                    } catch (err) {
                                        console.log(`Event error: ${err.message}`)
                                    }
                                }                                
                                
                            } else {
                                noResponse = true
                            }
                        }                        

                    }
                }
            )
            .catch(err =>
                console.log('Error: ', err.message)
            )


        // Get the first subsequent step where conditions are empty, or conditions are satisfied.

        let nextStep = step;
        let stepFound = false;

        while (stepFound === false) {
            let arrayIndexOfNextStep = nextStep; // Array starts at 0, step starts at 1. This brings them to the same level.
            nextStep = nextStep + 1;

            // Check the step for conditions
            if (nextStep <= (this.state.survey.length)) {
                if (this.state.survey[arrayIndexOfNextStep].conditions.length > 0) {

                    // Check if the conditions were met
                    let { conditions } = this.state.survey[arrayIndexOfNextStep];

                    let conditionsMet = false;
                    let i;

                    // Loop through conditions, and check if they match the values required. this.state.survey.conditions[ [{},{}], [{}] ]
                    for (i = 0; i < conditions.length; i += 1) {
                        // Single OR condition [{},{}]
                        let andConditions = conditions[i];
                        let andConditionsMet = false;
                        let l;

                        for (l = 0; l < andConditions.length; l += 1) {
                            // Single AND condition {}
                            let singleCondition = andConditions[l];
                            let stateQuestion = this.state.survey.filter(question => {
                                return question.questionId === singleCondition.questionId
                            })

                            // Check if the AND condition itself is an array. This means multiple values from the conditional question can satisfy this condition.
                            let pass = false;

                            //THIS IF CONDITION IS DEPRECATED NOW. ONLY WILL EVER USE THE ELSE. BUT LEAVE IN - INCASE WE NEED THIS.
                            if (Array.isArray(singleCondition.value)) {

                                // let singleOrConditions = singleCondition.value;
                                // let singleConditionMet = false;
                                // let k;

                                // for (k = 0; k < singleOrConditions.length; k += 1){ // NOTES looping [['Audi', 'BMW'], ['Audi', 'Mercedes-Benz']] 
                                //     // singleCondition OR
                                //     let singleAndConditions = singleOrConditions[k];
                                //     let singleAndConditionsMet = false;
                                //     let j;

                                //     for (j = 0; j < singleAndConditions.length; j += 1){ // NOTES looping ['Audi', 'BMW']
                                //         // singleCondition AND array

                                //         let singleAndConditionPass = false;                                        

                                //         if (Array.isArray(stateQuestion[0].response)){                                            
                                //             // If the answer in the state is an array, compare arrays
                                //             console.log('array')
                                //             if (singleAndConditions.every(r=>stateQuestion[0].response.includes(r))){                                                
                                //                 singleAndConditionPass = true;
                                //             }
                                //         } else {
                                //             console.log('string')
                                //             // It is not an array and we just see if it includes
                                //             if (singleAndConditions.includes(stateQuestion[0].response)){
                                //                 singleAndConditionPass = true
                                //             }
                                //         }

                                //         if (singleAndConditionPass){
                                //             singleAndConditionsMet = true;
                                //             continue;
                                //         } else {
                                //             singleAndConditionsMet = false;
                                //             break;
                                //         }
                                //     }

                                //     if (singleAndConditionsMet === true){
                                //         singleConditionMet = true;
                                //         break;
                                //     } else{
                                //         continue;
                                //     }

                                // }

                                // if (singleConditionMet === true){
                                //     pass = true;
                                // }

                            } else {
                                // The AND condition is not an array. We can just compare the value directly. OR check if there's an operator?

                                // Check if the response is an array (multiple choice multi)
                                if (Array.isArray(stateQuestion[0].response)) {
                                    if (stateQuestion[0].response.includes(singleCondition.value)) {
                                        pass = true;
                                    }
                                } else {
                                    if (singleCondition.value === '') {
                                        // Any value passes.
                                        pass = true
                                    } else if (stateQuestion[0].response === singleCondition.value) {
                                        pass = true
                                    } else if (singleCondition.operator !== '') {
                                        console.log(singleCondition.operator)
                                        if (singleCondition.operator === 'GT') {
                                            if (parseInt(stateQuestion[0].response) > parseInt(singleCondition.value)) {
                                                pass = true
                                            }
                                        }
                                        if (singleCondition.operator === 'LT') {
                                            if (parseInt(stateQuestion[0].response) < parseInt(singleCondition.value)) {
                                                pass = true
                                            }
                                        }
                                        if (singleCondition.operator === 'EQ') {
                                            if (parseInt(stateQuestion[0].response) === parseInt(singleCondition.value)) {
                                                pass = true
                                            }
                                        }
                                    }
                                }

                            }

                            //console.log("pass", pass)

                            if (pass) {
                                andConditionsMet = true;
                                continue;
                            } else {
                                andConditionsMet = false;
                                break;
                            }
                        }

                        if (andConditionsMet === true) {
                            conditionsMet = true;
                            break;
                        } else {
                            continue;
                        }

                    }

                    if (conditionsMet) {
                        // If they were met, then this is the next step.
                        stepFound = true;
                    } else {
                        // If they were not met, we m ove on.
                        stepFound = false;
                    }

                } else {
                    // This has no conditions. We're at the correct step.
                    stepFound = true;
                }
            } else {
                // No more questions. This is the final question.
                stepFound = true;
                nextStep = 'end';
            }
        }

        // Add the step we are leaving to the prevStep array.

        let stepList = this.state.stepList;
        stepList.push(step);

        if (nextStep === 'end') {
            // Remove AVTC cookie, as this survey is complete. However the click_id PERSISTS.
            const cookies = new Cookies();
            cookies.remove('avtc');
        }       

        this.setState({
            ...this.state,
            submitting: false,
            step: nextStep,
            stepList: stepList,
            maxStep: parseInt(this.state.survey.length) - (parseInt(nextStep) - parseInt(stepList.length) - 1), //This is the max possible steps after conditions
            list: list,
            callbacks: callbacks,
            iframe: iframe,
            skip: noResponse,
            scrollComplete: scrollComplete
        })       

        this.props.changePage(nextStep)

        this.scrollMobileView()
    }

    prevStep = () => {
        // We want to go to the highest step in the steplist, and then also remove it.
        let stepList = this.state.stepList;

        let lastStep = Math.max(...stepList)
        let indexOfLastStep = stepList.indexOf(lastStep);

        stepList.splice(indexOfLastStep, 1)

        this.setState({
            ...this.state,
            step: lastStep,
            stepList: stepList
        })

        this.props.changePage(lastStep)

        this.scrollMobileView()        
    }

    handleChange = (questionId, value, multiSelect, multiForm, multiFormKey) => {

        // Make a copy of the survey
        let clonedSurvey = _.cloneDeep(this.state.survey)

        let question = clonedSurvey.find(question => {
            return question.questionId === questionId
        })

        if (!multiSelect && !multiForm) {
            question.response = value
        } else if (multiSelect) {
            // Check if value is already there. If it is remove it. If not add it.            
            if (question.response.includes(value)) {
                let indexOfExisting = question.response.indexOf(value);
                question.response.splice(indexOfExisting, 1)
            } else {
                question.response.push(value);
            }
        } else if (multiForm) {
            // These values have to be an object.
            // MultiFormKey will be the key of the value.
            // If it's an array, change it to an object first.
            if (Array.isArray(question.response)) {
                question.response = {};
            }
            if (multiFormKey) {
                question.response[multiFormKey] = value
            }
        }

        this.setState({
            ...this.state,
            survey: clonedSurvey
        })

    }

    getQuestion = () => {
        let { step } = this.state;
        let questionComponent;

        if (!isNaN(step)) {
            let question = this.state.survey.find(question => {
                return question.order === step;
            })

            let questionType = questionTypes.find(questionType => {
                return questionType.type === question.type;
            })

            let lastQuestion = false;
            if (step >= this.state.survey.length) {
                lastQuestion = true;
            }

            if (questionType) {
                questionComponent = questionType.component(this.state.survey, question, question.placeholder, this.handleChange, this.nextStep, lastQuestion, this.getNextButton(step), step)
            } else {
                questionComponent = <div>No question for this type.</div>
            }

        } else {
            // The survey is finished. We need to display the correct ending based on the complete events (mode = 1).   
            
            // See if there are any completion events with conditions
            let completionEvent = this.checkCompletionEvent(this.state.events)

            // If the type is iFrame, we no longer just load it in the question, we are replacing the layout
            if (this.state.iframe!=='' && completionEvent && !this.state.skip){
                this.props.loadIframe(this.state.iframe)
                return
            }

            questionComponent = <React.Fragment>
                <h3 className="text-xlarge text-left" style={{ lineHeight: '50px' }}>
                    {(completionEvent && !this.state.skip) ? completionEvent.eventCompleteMsg : 'Thank you!'}
                </h3>
                {this.state.list.length > 0 &&
                    <div>
                        {this.state.list.map(adItem => {
                            return <div className="mb-2"><AdListItem key={adItem.listTitle} button={adItem.listButtonText} title={adItem.listTitle} description={adItem.listParagraph} image={adItem.listImage} url={adItem.listUrl} /></div>
                        })}
                    </div>
                }
                {/* {this.state.iframe !== '' &&
                    <iframe title='Result' className="m-3" style={{ width: '100%' }} src={this.state.iframe}></iframe>
                } */}
            </React.Fragment>
        }

        return questionComponent;

    }

    getSurveyClass = (mbClass) => {
        let classes = `mb-survey d-flex align-items-center ${mbClass}`;
        return classes;
    }

    getNextButton = (step) => {
        let question = this.state.survey.find(question => {
            return question.order === step
        })

        // We only show the next button if this question has a value.
        // Is the answer an array? Multiselect
        // Is the answer an object? short Form
        // Is the not an array NOR an object? Regular

        // Get the short form question type
        let questionType = questionTypes.find(questionType => {
            return questionType.type === question.type
        })

        const checkCompletion = (question) => {
            let complete = true;

            // Now, check if each REQUIRED key has a value
            questionType.requiredFields.forEach(field => {
                if (!question.response[field]) {
                    complete = false;
                } else if (question.response[field] === '') {
                    complete = false;
                }
            })

            return complete;
        }

        // typeof will return object, for both objects and array, so we have to use Array.isArray
        let nextButtonObj = {
            type: false,
            disable: false
        }

        if (question.callToAction !== '' || (question.callToAction === '' && questionType.disableNext === true)){
            nextButtonObj.disable = true;
        }

        // console.log(question, `Is array: ${Array.isArray(question.response)}`, `type of: ${typeof question.response}`, question.response)        
        if ((Array.isArray(question.response) && question.response.length !== 0) ||
            (!Array.isArray(question.response) && typeof question.response === 'object' && checkCompletion(question)) ||
            (!Array.isArray(question.response) && typeof question.response !== 'object' && question.response !== '')) {
            if (step >= this.state.survey.length) {
                // This is the last question. We want to show the submit button (or CTA)
                nextButtonObj.type = 'submit'
            } else {
                // This is not the last question, we want to show the next button.
                nextButtonObj.type = 'next'                
            }
        } else {
            // The question is not filled out. We only will show a button for the last page, but it will be disabled.
            if (step >= this.state.survey.length) {
                // This is the last question. We want to show the submit button.
                nextButtonObj.type = 'submitDisabled'                
            } else {
                // Hide / disable the button
                nextButtonObj.type = false
            }
        }

        return nextButtonObj;
    }

    displayNextButton = (nextButtonObj) => {
        if (!nextButtonObj.disable){
            if (nextButtonObj.type === 'submit'){
                return <button className="btn btn-lg btn-info btn-block" onClick={() => this.nextStep(true)}>{this.props.survey.submitButton}</button>
            } else if (nextButtonObj.type === 'next'){
                return <button onClick={() => this.nextStep(false)} className="btn btn-lg btn-primary btn-block">Next</button>
            } else if (nextButtonObj.type === 'submitDisabled'){
                return <button className="btn btn-lg btn-secondary btn-block" disabled>{this.props.survey.submitButton}</button>
            } else {
                return <button disabled className="btn btn-lg btn-primary btn-block">Next</button>
            }
        }    
        
        return;
        
    }

    getLoadingBar = () => {
        let width = 0;
        if (!isNaN(this.state.step)) {
            width = (this.state.stepList.length) / this.state.maxStep * 100;
        } else {
            width = 100;
        }

        return { width: `${width}%` }

    }

    getQuestionContainerClass = () => {
        if (isNaN(this.state.step)) {
            // We are at the end.
            if (this.props.layout === 2 || this.props.layout === 3) {
                let completionEvent = this.checkCompletionEvent(this.state.events)
                if (completionEvent && this.state.scrollComplete) {
                    return "question-container-scroll m-auto"
                } else {
                    return "question-container m-auto"
                }
            } else {
                return "question-container m-auto"
            }
        } else {
            return "question-container m-auto"
        }
    }

    checkCompletionEvent = (events) => {
        let completionEvent = events.find(event => {
            if (event.mode === 1 && event.conditions.length > 0) {

                // Check if any conditions were met
                let conditionsMet = false;
                let i;

                for (i = 0; i < event.conditions.length; i += 1) {

                    let innerConditions = event.conditions[i];
                    let innerConditionsMet = false;
                    let l;

                    // Loop through the inner AND conditions now
                    for (l = 0; l < innerConditions.length; l += 1) {

                        let pass = false;

                        let innerCondition = innerConditions[l];

                        let conditionQuestion = this.state.survey.find(question => {
                            return question.dbQuestionId === innerCondition.questionId
                        })

                        // Check if the response is an array (multiple choice multi)
                        if (Array.isArray(conditionQuestion.response)) {
                            if (conditionQuestion.response.includes(innerCondition.value)) {
                                pass = true;
                            }
                        } else {
                            if (conditionQuestion.response === innerCondition.value) {
                                //console.log(`${conditionQuestion.question}: ${conditionQuestion.response} = ${innerCondition.value}`)
                                pass = true
                            } else if (innerCondition.operator !== '') {
                                if (innerCondition.operator === 'GT') {
                                    if (parseInt(conditionQuestion.response) > parseInt(innerCondition.value)) {
                                        //console.log(`${conditionQuestion.response} GT ${innerCondition.value}`)
                                        pass = true
                                    }
                                }
                                if (innerCondition.operator === 'LT') {
                                    if (parseInt(conditionQuestion.response) < parseInt(innerCondition.value)) {
                                        //console.log(`${conditionQuestion.response} LT ${innerCondition.value}`)
                                        pass = true
                                    }
                                }
                                if (innerCondition.operator === 'EQ') {
                                    if (parseInt(conditionQuestion.response) === parseInt(innerCondition.value)) {
                                        //console.log(`${conditionQuestion.response} EQ ${innerCondition.value}`)
                                        pass = true
                                    }
                                }
                            }
                        }

                        if (pass) {
                            innerConditionsMet = true;
                            continue;
                        } else {
                            innerConditionsMet = false;
                            break;
                        }

                    }

                    if (innerConditionsMet) {
                        conditionsMet = true;
                        break;
                    } else {
                        continue;
                    }

                }

                return conditionsMet;
            }

            return false;
        })

        if (!completionEvent) {
            // Find a default complete event with no conditions.
            completionEvent = events.find(event => {
                if (event.mode === 1 && event.conditions.length === 0) {
                    return true;
                }
                return false;
            })
        }

        return completionEvent
    }

    loadCallbacks = () => {
        if (this.state.callbacks.length > 0){
            // Loop through and load the callbacks                
            return this.state.callbacks.map( (callback,index) => {
                if (callback !== ''){
                    return <iframe key={index} title='mb-cb' src={`${callback}`} style={{height:"0px", width:"0px", border:"none"}}></iframe>
                } else {
                    return false
                }
            })
        } else {
            return false;
        }
    }

    onGetUniversalLeadId = (leadId) => {        
        this.setState({
            ...this.state,
            jornaya: leadId
        })
    }

    render() {
        return (
            this.state.dataLoaded ?
                <div className={this.getSurveyClass(this.props.mbClass)}>
                    <Jornaya jornayaToken='d2fb0ec8-1e8c-a402-e4ac-9205fe98f622' onGetUniversalLeadId={this.onGetUniversalLeadId} leadId={this.state.jornaya}/>
                    { this.props.pushnamiOverride === false && this.props.survey.pushnami.enabled && this.state.step >= this.props.survey.pushnami.step && <Pushnami pushnamiToken={this.props.survey.pushnami.id} /> }
                    <div className="mb-survey-loading" id="surveyRef" ref={this.surveyRef}><div className="mb-survey-loading-bar" style={this.getLoadingBar()}></div></div>
                    <div className={this.getQuestionContainerClass()}>                        
                        {this.getQuestion()}
                        {this.state.step >= this.state.survey.length &&
                            <React.Fragment>
                                <div className="d-flex flex-row-reverse justify-content-center mt-3">
                                    <div className="pl-2 flex-1">
                                        {/* This is the submit button */}
                                        { !this.state.submitting ?
                                            this.displayNextButton(this.getNextButton(this.state.step))
                                        :
                                            /* It is submitting */
                                            !this.getNextButton(this.state.step).disable ?
                                                <button style={{ height: '48px', width: '75px' }} className="btn btn-secondary btn-sm" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                                            :
                                                <React.Fragment></React.Fragment>
                                        }
                                    </div>
                                </div>
                                {this.props.survey.submitDisclaimer !== '' &&
                                    <div className="mt-5">
                                        <p dangerouslySetInnerHTML={{ __html: this.props.survey.submitDisclaimer }} className="text-muted text-xsmall text-left"></p>
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </div>
                    {!isNaN(this.state.step) && this.state.step > 1 &&
                        <div className={`position-absolute back-step-${this.props.layout}`}>
                            <button onClick={this.prevStep} className="btn btn-lg btn-secondary btn-block">Back</button>
                        </div>
                    }
                    {!isNaN(this.state.step) && this.state.step < this.state.survey.length &&
                        <div className={`position-absolute next-step-${this.props.layout}`}>
                            {!this.state.submitting ?
                                this.displayNextButton(this.getNextButton(this.state.step))
                            :
                                /* It is submitting */
                                !this.getNextButton(this.state.step).disable ?                                            
                                    <button style={{ height: '48px', width: '75px' }} className="btn btn-secondary btn-sm" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                                :
                                    <React.Fragment></React.Fragment>                                
                            }
                        </div>
                    }
                    {
                        this.loadCallbacks()
                    }
                </div>
                :
                <div className="h-100">
                    <Loading></Loading>
                </div>
        );
    }

}

export default Survey;