import MultipleChoice from '../MultipleChoice/MultipleChoice'
import MultipleChoiceMulti from '../MultipleChoiceMulti/MultipleChoiceMulti';
import TextShort from '../TextShort/TextShort';
import TextLong from '../TextLong/TextLong';
import TextInt from '../TextInt/TextInt';
import YesNo from '../YesNo/YesNo';
import CountryDropdown from '../CountryDropdown/CountryDropdown';
import ShortFormName from '../ShortFormName/ShortFormName';
import ShortFormNameEmail from '../ShortFormNameEmail/ShortFormNameEmail';
import ShortFormAddress from '../ShortFormAddress/ShortFormAddress';
import Message from '../Message/Message';
import Dropdown from '../Dropdown/Dropdown';
import ShortFormContact from '../ShortFormContact/ShortFormContact';
import ShortFormAddressPhone from '../ShortFormAddressPhone/ShortFormAddressPhone';
import ShortFormVehicle from '../ShortFormVehicle/ShortFormVehicle';
import DateSelector from '../DateSelector/DateSelector';
import StateDropdown from '../StateDropdown/StateDropdown';
import Redirect from '../Redirect/Redirect';

const questionTypes = [
    {
        name: 'Yes/No',
        type: 'YN',
        requiredFields: [],
        disableNext: true,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <YesNo key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} nextStep={nextStep} lastQuestion={lastQuestion} getNextButton={getNextButton}/>}
    },
    {
        name: 'Text Short',
        type: 'TS',
        requiredFields: [],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <TextShort key={step} fullSurvey={fullSurvey} data={question} placeholder={placeholder} handleChange={handleChange} lastQuestion={lastQuestion} nextStep={nextStep} getNextButton={getNextButton}/>}
    },
    {
        name: 'Text Long',
        type: 'TL',
        requiredFields: [],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <TextLong key={step} fullSurvey={fullSurvey} data={question} placeholder={placeholder} handleChange={handleChange} lastQuestion={lastQuestion} nextStep={nextStep} getNextButton={getNextButton}/>}
    },
    {
        name: 'Number',
        type: 'TI',
        requiredFields: [],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <TextInt key={step} fullSurvey={fullSurvey} data={question} placeholder={placeholder} handleChange={handleChange} lastQuestion={lastQuestion} nextStep={nextStep} getNextButton={getNextButton}/>}
    },
    {
        name: 'Multiple Choice',
        type: 'MC',
        requiredFields: [],
        disableNext: true,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <MultipleChoice key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} nextStep={nextStep} lastQuestion={lastQuestion}/>}
    },
    {
        name: 'Multiple Choice Multi-select',
        type: 'MCM',
        requiredFields: [],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <MultipleChoiceMulti key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange}/>}
    },
    {
        name: 'Dropdown',
        type: 'DD',
        requiredFields: [],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <Dropdown key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} nextStep={nextStep} lastQuestion={lastQuestion}/>}
    },
    {
        name: 'Country Dropdown',
        type: 'CD',
        requiredFields: [],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <CountryDropdown key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} nextStep={nextStep} lastQuestion={lastQuestion}/>}
    },
    {
        name: 'State Dropdown',
        type: 'SD',
        requiredFields: [],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <StateDropdown key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} nextStep={nextStep} lastQuestion={lastQuestion}/>}
    },
    {
        name: 'Short Form - Name',
        type: 'SFN',
        shortForm: true,
        requiredFields: ['firstName', 'lastName'],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <ShortFormName key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} lastQuestion={lastQuestion} requiredFields={this.requiredFields} nextStep={nextStep} getNextButton={getNextButton}/>}
    },
    {
        name: 'Short Form - Name / Email',
        type: 'SFNE',
        shortForm: true,
        requiredFields: ['firstName', 'lastName', 'email'],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <ShortFormNameEmail key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} placeholder={placeholder} lastQuestion={lastQuestion} requiredFields={this.requiredFields} nextStep={nextStep} getNextButton={getNextButton}/>}
    },
    {
        name: 'Short Form - Contact',
        type: 'SFC',
        shortForm: true,
        requiredFields: ['firstName', 'lastName', 'email', 'phone'],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <ShortFormContact key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} lastQuestion={lastQuestion} requiredFields={this.requiredFields} nextStep={nextStep} getNextButton={getNextButton}/>}
    },
    {
        name: 'Short Form - Address',
        type: 'SFA',
        shortForm: true,
        requiredFields: ['address1', 'city', 'state', 'country', 'zipcode'],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <ShortFormAddress key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} lastQuestion={lastQuestion} requiredFields={this.requiredFields} nextStep={nextStep} getNextButton={getNextButton}/>}
    },
    {
        name: 'Short Form - Address / Phone',
        type: 'SFAP',
        shortForm: true,
        requiredFields: ['address', 'phone'],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <ShortFormAddressPhone key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} lastQuestion={lastQuestion} requiredFields={this.requiredFields} nextStep={nextStep} getNextButton={getNextButton}/>}
    },
    {
        name: 'Short Form - Vehicle',
        type: 'SFV',
        shortForm: true,
        requiredFields: ['year', 'make', 'model', 'trim'],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <ShortFormVehicle key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} requiredFields={this.requiredFields}/>}
    },
    {
        name: 'Message',
        type: 'MSG',
        requiredFields: [],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <Message key={step} fullSurvey={fullSurvey} data={question} lastQuestion={lastQuestion} nextStep={nextStep} getNextButton={getNextButton}/>}
    },
    {
        name: 'Date Selector',
        type: 'DS',
        requiredFields: [],
        disableNext: false,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <DateSelector key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} lastQuestion={lastQuestion} nextStep={nextStep} getNextButton={getNextButton} />}
    },
    {
        name: 'Redirect',
        type: 'RD',
        requiredFields: [],
        disableNext: true,
        component: function(fullSurvey, question, placeholder, handleChange, nextStep, lastQuestion, getNextButton, step){return <Redirect key={step} fullSurvey={fullSurvey} data={question} handleChange={handleChange} placeholder={placeholder} nextStep={nextStep} lastQuestion={lastQuestion}/>}
    },
]

export default questionTypes;